import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby";
import { getBlockContent, getBlockImage } from "../utils/helpers";
import { superEvent } from "../utils/superEvent";
import { toPlainText } from "../../../sanity/helpers/sanitySchemaHelpers";
import { imageUrlFor } from "../utils/image-url";

// Containers
import RenderRichContent from "../components/containers/RenderRichContent";
// import Img from "gatsby-image"

const Article = ({ data }) => {
  const { sanityArticle = {} } = data;

  const {
    title = "",
    lead,
    longTitle,
    _rawContent,
    metaInfo,
    slug,
  } = sanityArticle;
  // const content = _rawContent  || [];

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || longTitle,
    description: metaInfo?.description || lead || getBlockContent(_rawContent),
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
    type: "article",
  };

  const pageSchemas = {
    "@context": "https://schema.org",
    "@type": "Blog",
    name: metaInfo?.header || title || "",
    description:
      metaInfo?.description || lead || getBlockContent(_rawContent) || " ",
    text: _rawContent?.find(obj => obj?.textContent)
      ? toPlainText(
          _rawContent?.find(obj => obj?.textContent)?.textContent
        ).replace(/(\r\n|\n|\r)/gm, "")
      : "",
    creator: {
      "@type": "Organization",
      name: "Y Oslo",
      url: "https://www.y-oslo.com/",
    },
    sourceOrganization: {
      "@type": "Organization",
      name: "Netlife Design",
      url: "https://www.netlife.com/",
    },
    url: `https://www.y-oslo.com/${slug?.current}`,
    datePublished: sanityArticle?._createdAt,
    dateCreated: sanityArticle?._createdAt,
    dateModified: sanityArticle?._updatedAt,
    image: {
      "@type": "ImageObject",
      contentUrl:
        metaInfo?.image?.asset?.url ||
        imageUrlFor(getBlockImage(_rawContent)?.image).url() ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
      url:
        metaInfo?.image?.asset?.url ||
        imageUrlFor(getBlockImage(_rawContent)?.image).url() ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
    },
    subjectOf: superEvent,
  };

  return (
    <Layout metaInfo={metaStructure} pageSchemas={pageSchemas}>
      <Helmet>
        <meta
          property="og:article:published_time"
          content={sanityArticle?._createdAt}
        />
        <meta
          property="og:article:modified_time"
          content={sanityArticle?._updatedAt}
        />
      </Helmet>
      {/* <p className="text-2xl">{data.sanityPerson.name}</p> */}

      {/* Page title */}
      <div
        className="
          flex flex-col
          md:flex-row
        "
      >
        <h1 className="text-4xl mb-4 uppercase motion-safe:animate-symbol-bounce-slow">
          {title}
        </h1>

        {/* <img
          className="
            order-1 md:order-2
            object-cover
            h-48"
          src="https://scontent.fsvg1-1.fna.fbcdn.net/v/t1.0-9/71273075_2710470789016111_3315885404447571968_o.jpg?_nc_cat=102&_nc_ohc=FsfAasoRcsYAX-9NOdh&_nc_ht=scontent.fsvg1-1.fna&oh=e8ac15112931ad3aef471d23f4c03ed8&oe=5EB990BE"
          alt=""
        /> */}
      </div>

      {/* Intro text */}
      <p
        className="
          mb-8 md:mb-16
          max-w-sm
          auto
          font-bold
        "
      >
        {lead}
      </p>

      {_rawContent.map((content, i) => (
        <RenderRichContent key={`${i}_content`} content={content} />
      ))}
    </Layout>
  );
};
export default Article;

export const query = graphql`
  query($id: String!) {
    sanityArticle(id: { eq: $id }) {
      _createdAt
      _updatedAt
      id
      title
      lead
      longTitle
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
